import { Splide, SplideSlide } from '@splidejs/react-splide';
import { FC, memo, useMemo } from 'react';
import cls from './instrumentsPackagesModal.module.css';
import './splide.css';
import { Package } from '@/entities/Package';
import { classNames } from '@/shared/lib/classNames/classNames';
import { getArrForSliderInstruments } from '@/shared/lib/helpers/getArrForSliderInstruments';
import { useDetectDevice } from '@/shared/lib/hooks/useDetectDevice';
import { AppImage } from '@/shared/ui/AppImage';
import { Modal } from '@/shared/ui/Modal';
import { GStack, HStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
import '@splidejs/react-splide/css';

interface IInstrumentsPackagesModalProps {
  className?: string;
  openModal: boolean;
  closeModal: () => void;
  packageLocal: Package;
}

export const InstrumentsPackagesModal: FC<IInstrumentsPackagesModalProps> =
  memo(props => {
    const { className, closeModal, openModal, packageLocal } = props;

    const { isMobile } = useDetectDevice();

    const size = useMemo(() => (isMobile ? 16 : 24), [isMobile]);
    const arrPaginationInstruments = useMemo(
      () => getArrForSliderInstruments(packageLocal.tradeInstruments, size),
      [packageLocal.tradeInstruments, size],
    );

    const packagesBlockSliderOptions = {
      omitEnd: true,
      pagination: true,
      perMove: 1,
      perPage: 1,
    };

    const gtc = useMemo(() => {
      if (isMobile) return 'repeat(2, 1fr)';
      return 'repeat(4, 1fr)';
    }, [isMobile]);
    const sizeProperty = { height: 20, width: 20 };
    const classNameWrapper = classNames(cls['instruments-packages-modal'], {}, [
      className,
      'instruments-slide-wrapper',
    ]);

    return (
      <Modal
        withCloseButton
        isOpen={openModal}
        className={classNameWrapper}
        onClose={closeModal}
      >
        <Text as='h4' variant='h4'>
          {packageLocal.name}
        </Text>
        <Splide options={packagesBlockSliderOptions}>
          {arrPaginationInstruments.map((partArray, index) => (
            <SplideSlide key={index}>
              <GStack className={cls.items} gtc={gtc} gapC='s' gapR='m'>
                {partArray.map(({ iconLink, id, name }) => (
                  <HStack
                    key={id}
                    gap='x-s'
                    align='center'
                    style={{ minWidth: 100 }}
                  >
                    {iconLink ? (
                      <AppImage src={iconLink} alt={name} {...sizeProperty} />
                    ) : (
                      <div style={{ ...sizeProperty }} />
                    )}
                    <Text as='span' variant='body-s-uppercase'>
                      {name}
                    </Text>
                  </HStack>
                ))}
              </GStack>
            </SplideSlide>
          ))}
        </Splide>
      </Modal>
    );
  });
