import { useTranslations } from 'next-intl';
import { FC, memo } from 'react';
import cls from './infoModal.module.css';
import { ErrorCircleSVG, SuccessCircleSVG } from '@/shared/assets/svg/Statuses';
import { classNames } from '@/shared/lib/classNames/classNames';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { Modal } from '@/shared/ui/Modal';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface IInfoModalProps {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  addInfo?: string;
  messageStatus?: 'error' | 'success';
  titleText: string;
  ordinaryText: { firstPart: string; secondPart: null | string };
}

export const InfoModal: FC<IInfoModalProps> = memo(props => {
  const {
    className,
    isOpen,
    onClose,
    addInfo,
    messageStatus,
    titleText,
    ordinaryText,
  } = props;
  const t = useTranslations();

  const statusSVG =
    messageStatus === 'error'
      ? { color: '#FF5449', icon: ErrorCircleSVG }
      : { color: '#14E0AE', icon: SuccessCircleSVG };

  return (
    <Modal
      isOpen={isOpen}
      className={classNames(cls['info-modal'], {}, [className])}
      onClose={onClose}
    >
      <VStack gap='x-l' justify='center' align='center'>
        <Icon
          color={statusSVG.color}
          height={80}
          width={80}
          Svg={statusSVG.icon}
        />
        <VStack gap='m' justify='center' align='center'>
          <Text as='h3' variant='h3'>
            {t(titleText)}
          </Text>
          <Text
            align='center'
            variant='body-l-light'
            className={cls['gray-text']}
          >
            {t(ordinaryText.firstPart)}
            {!!addInfo && (
              <Text
                as='span'
                variant='body-l-light'
                className={cls['green-text']}
                wrap='wrap'
              >
                {addInfo}
              </Text>
            )}
            {!!ordinaryText.secondPart && (
              <Text
                as='span'
                variant='body-l-light'
                className={cls['gray-text']}
                wrap='wrap'
              >
                {t(ordinaryText.secondPart)}
              </Text>
            )}
          </Text>
        </VStack>
        <Button
          fullWidth
          typeButton='primary-outline'
          type='submit'
          text={t('Got it')}
          className={cls.button}
          onClick={onClose}
        />
      </VStack>
    </Modal>
  );
});
