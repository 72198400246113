import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPartner } from '../../types/partner';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

interface ISetPermanentPartnerPasswordPartnerProps {
  email: string;
  oldPassword: string;
  permPassword: string;
}

export const setPermanentPartnerPasswordReq = createAsyncThunk<
  IPartner,
  ISetPermanentPartnerPasswordPartnerProps,
  ThunkConfig<string>
>('partner/setPermanentPassword', async (setPasswordData, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.patch<IPartner>(
      urls.partners.setPermanentPassword,
      {
        email: setPasswordData.email,
        newPassword: setPasswordData.permPassword,
        oldPassword: setPasswordData.oldPassword,
      },
    );

    if (!response.data) throw new Error(response as any);

    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error?.response?.data?.message);
  }
});
