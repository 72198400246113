import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPartner } from '../../types/partner';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

export const resendParnterPasswordReq = createAsyncThunk<
  IPartner,
  string,
  ThunkConfig<string>
>('partner/resendPassword', async (email, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IPartner>(
      urls.partners.resendPassword,
      { email },
    );

    if (!response.data) throw new Error(response as any);

    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error?.response?.data?.message);
  }
});
