import { useTranslations } from 'next-intl';
import { FC, memo, useCallback, useMemo, useState } from 'react';
import cls from './setPermanentPasswordModal.module.css';
import { setPermanentPartnerPasswordReq } from '@/entities/Partner';
import { changeUserPasswordReq } from '@/entities/User';
import {
  EyeInvisibleOutlinedSVG,
  EyeOutlinedSVG,
} from '@/shared/assets/svg/Authorization';
import {
  getRoutePartnershipPage,
  getRoutePersonAccMain,
} from '@/shared/const/router';
import { classNames } from '@/shared/lib/classNames/classNames';
import { useAppDispatch } from '@/shared/lib/hooks/redux';
import { useAppNavigation } from '@/shared/lib/hooks/useAppNavigation/useAppNavigation';
import { Button } from '@/shared/ui/Buttons';
import { Icon } from '@/shared/ui/Icon';
import { Input } from '@/shared/ui/Inputs';
import { Modal } from '@/shared/ui/Modal';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';

interface ISetPermanentPasswordModalProps {
  className?: string;
  isOpen: boolean;
  authType: 'partner' | 'user';
  email: string;
  oldPassword: string;
  onClose: (userEmail: string, password: string) => void;
}

export const SetPermanentPasswordModal: FC<ISetPermanentPasswordModalProps> =
  memo(props => {
    const { className, isOpen, authType, email, oldPassword, onClose } = props;
    const t = useTranslations();
    const dispatch = useAppDispatch();
    const { push } = useAppNavigation();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const [isVisiblePassword, setVisiblePassword] = useState(false);
    const [isVisibleConfirmPassword, setVisibleConfirmPassword] =
      useState(false);

    const passEyeIcon = useMemo(
      () => (isVisiblePassword ? EyeInvisibleOutlinedSVG : EyeOutlinedSVG),
      [isVisiblePassword],
    );
    const confirmPassEyeIcon = useMemo(
      () =>
        isVisibleConfirmPassword ? EyeInvisibleOutlinedSVG : EyeOutlinedSVG,
      [isVisibleConfirmPassword],
    );

    const changePassword = (value: string) => setPassword(value);
    const changeConfirmPassword = (value: string) => setConfirmPassword(value);

    const changeErrorPassword = (value: string) => setPasswordError(value);
    const changeErrorConfirmPassword = (value: string) =>
      setConfirmPasswordError(value);

    const toggleVisiblePassword = () => setVisiblePassword(!isVisiblePassword);
    const toggleVisibleConfirmPassword = () =>
      setVisibleConfirmPassword(!isVisibleConfirmPassword);

    const isBtnDisabled = useMemo(
      () =>
        Boolean(
          passwordError ||
            confirmPasswordError ||
            password !== confirmPassword ||
            !password.length ||
            !confirmPassword.length,
        ),
      [confirmPassword, confirmPasswordError, password, passwordError],
    );

    const currentErrorConfirmPassword = useMemo(
      () =>
        password !== confirmPassword
          ? t('Passwords should be the same')
          : confirmPasswordError,
      [confirmPassword, confirmPasswordError, password, t],
    );

    const onHandleSubmit = useCallback(async () => {
      if (authType === 'partner') {
        await dispatch(
          setPermanentPartnerPasswordReq({
            email,
            oldPassword,
            permPassword: password,
          }),
        ).then(() => onClose(email, password));
        if (!passwordError)
          push({
            paramsToDelete: ['callbackUrl'],
            path: getRoutePartnershipPage(),
          });
      }

      if (authType === 'user') {
        await dispatch(
          changeUserPasswordReq({
            email,
            newPassword: password,
            oldPassword,
          }),
        ).then(() => onClose(email, password));
        if (!passwordError)
          push({
            paramsToDelete: ['callbackUrl'],
            path: getRoutePersonAccMain(),
          });
      }
    }, [
      dispatch,
      authType,
      email,
      push,
      onClose,
      password,
      oldPassword,
      passwordError,
    ]);

    return (
      <Modal
        isOpen={isOpen}
        className={classNames(cls['set-permanent-password-modal'], {}, [
          className,
        ])}
      >
        <VStack gap='x-l'>
          <VStack gap='s' justify='center' align='start'>
            <Text as='h3' variant='h3'>
              {t('Setting a permanent password')}
            </Text>
            <Text as='p' variant='body-m' className={cls['gray-text']}>
              {t('To start using service')}
            </Text>
          </VStack>
          <VStack className={cls['inputs-block']}>
            <Input
              isValidate
              required
              type={isVisiblePassword ? 'text' : 'password'}
              name='password'
              label={t('Password')}
              placeholder={t('Enter password')}
              value={password}
              data-testid='SetPermanentPasswordModal.password'
              error={passwordError}
              errorClass={cls['error-password']}
              setError={changeErrorPassword}
              classNameWrapper={cls['input-wrapper']}
              endIcon={
                <Icon
                  clickable
                  width={24}
                  height={24}
                  className={cls['eye-icon']}
                  Svg={passEyeIcon}
                  onClick={toggleVisiblePassword}
                />
              }
              onChange={changePassword}
            />
            <Input
              isValidate
              required
              type={isVisibleConfirmPassword ? 'text' : 'password'}
              name='password'
              label={t('Password confirmation')}
              placeholder={t('Confirm password')}
              value={confirmPassword}
              data-testid='SetPermanentPasswordModal.confirmPassword'
              errorClass={cls['error-password']}
              setError={changeErrorConfirmPassword}
              classNameWrapper={cls['input-wrapper']}
              isError={password !== confirmPassword}
              error={currentErrorConfirmPassword}
              endIcon={
                <Icon
                  clickable
                  width={24}
                  height={24}
                  className={cls['eye-icon']}
                  Svg={confirmPassEyeIcon}
                  onClick={toggleVisibleConfirmPassword}
                />
              }
              onChange={changeConfirmPassword}
            />
          </VStack>
        </VStack>
        <Button
          fullWidth
          typeButton='primary-outline'
          type='submit'
          text={t('Set a password')}
          disabled={isBtnDisabled}
          onClick={onHandleSubmit}
        />
      </Modal>
    );
  });
