import { createAsyncThunk } from '@reduxjs/toolkit';
import { IPartner } from '../../types/partner';
import { ThunkConfig } from '@/app-fsd/providers/StoreProvider';
import urls from '@/shared/const/urls';

interface ISignUpPartnerProps {
  email: string;
  name: string;
  phoneNumber: string;
}

export const signUpPartnerReq = createAsyncThunk<
  IPartner,
  ISignUpPartnerProps,
  ThunkConfig<string>
>('partner/signUp', async ({ email, name, phoneNumber }, thunkApi) => {
  const { extra, rejectWithValue } = thunkApi;

  try {
    const response = await extra.api.post<IPartner>(urls.partners.signUp, {
      email,
      name,
      phoneNumber,
    });

    if (!response.data) throw new Error(response as any);

    return response.data;
  } catch (error: any) {
    console.log(error);
    return rejectWithValue(error?.response?.data?.message || 'error signup');
  }
});
