// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { TradeInstruments } from '@/entities/Package';

export const getArrForSliderInstruments = (
  arr: TradeInstruments[],
  size: number,
) => {
  const tempArr = [...Array(Math.ceil(arr.length / size) + 1).keys()].slice(1);
  return tempArr.map(item => arr.slice((item - 1) * size, item * size));
};
